import findTopDomain from '@segment/top-domain';
export { default as findTopDomain } from '@segment/top-domain';

const debug = (...args) => {
  if (window.location.search.search(/[?&]wmpsm_debug=[1t]/) !== -1) {
    console.info(...args);
  }
};
function cookieHelper(name, value, options) {
  switch (arguments.length) {
    case 3:
    case 2:
      return set(name, value, options);

    case 1:
      return get(name);

    default:
      return all();
  }
}
/**
 * Set cookie `name` to `value`.
 */

function set(name, value, options) {
  options = options || {};
  let str = encode(name) + '=' + encode(value);

  if (options.encode === false) {
    str = name + '=' + value;
  }

  if (null == value) options.maxage = -1;

  if (options.maxage && !options.expires) {
    options.expires = new Date(+new Date() + options.maxage);
  }

  if (options.path) str += '; Path=' + options.path;
  if (options.domain) str += '; Domain=' + options.domain;
  if (options.expires) str += '; Expires=' + options.expires.toUTCString();
  if (options.samesite) str += '; SameSite=' + options.samesite;
  if (options.secure) str += '; Secure';
  debug('[COOKIE]: cookie.helper.set()', str, options);
  document.cookie = str;
}
/**
 * Return all cookies.
 */


function all() {
  let str;

  try {
    str = document.cookie;
  } catch (err) {
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      console.error(err.stack || err);
    }

    return {};
  }

  return parse(str);
}
/**
 * Get cookie `name`.
 */


function get(name) {
  const val = all()[name];
  debug(`[COOKIE]: helper.get() - getting ${name} cookie value ${val}`);
  return val;
}
/**
 * Parse cookie `str`.
 */


function parse(str) {
  const obj = {};
  const pairs = str.split(/ *; */);
  if ('' == pairs[0]) return obj;

  for (let i = 0; i < pairs.length; ++i) {
    const cookieName = pairs[i].substr(0, pairs[i].indexOf('='));
    const cookieValue = pairs[i].substr(pairs[i].indexOf('=') + 1);
    obj[decode(cookieName)] = decode(cookieValue);
  }

  return obj;
}

function encode(value) {
  try {
    return encodeURIComponent(value);
  } catch (err) {// ignore
  }
}

function decode(value) {
  try {
    return decodeURIComponent(value);
  } catch (err) {// ignore
  }
}

class Cookie {
  constructor(options) {
    this._options = {};
    this.options = this.options.bind(this);
    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.getAll = this.getAll.bind(this);
    this.remove = this.remove.bind(this);
    this.options(options);
  }

  options(options) {
    if (arguments.length === 0) return this._options;
    options = options || {};
    let domain = options.domain || `.${findTopDomain(window.location.href)}`;

    if (domain === '.') {
      domain = null;
    }

    this._options = {
      maxage: 31536000000,
      path: '/',
      samesite: 'Lax',
      encode: true,
      ...options,
      domain
    }; // http://curl.haxx.se/rfc/cookie_spec.html
    // https://publicsuffix.org/list/effective_tld_names.dat
    //
    // try setting a dummy cookie with the options
    // if the cookie isn't set, it probably means
    // that the domain is on the public suffix list
    // like myapp.deployment.com or localhost / ip.

    this.set('psm:test', true, this._options);

    if (!this.get('psm:test')) {
      debug('[Cookie]: psm:test cookie could not be set at domain: ', this._options.domain);
      const reg = new RegExp(document.location.hostname, 'g');

      if (document.referrer !== '' && document.referrer.search(reg) === -1) {
        this._options.samesite = 'None';
      } else {
        this._options.domain = null;
      }
    }

    this.remove('psm:test');
    debug('[COOKIE]: this._options', this._options);
  }

  set(name, value, options = {}) {
    const opts = { ...this._options,
      ...options
    };
    debug('[COOKIE]: this.set() options', opts);

    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      } // @ts-ignore


      cookieHelper(name, value === 'null' ? null : value, opts);
      return true;
    } catch (err) {
      debug('[COOKIE]: this.set() encountered an error', err);
      return false;
    }
  }

  get(name) {
    const value = cookieHelper(name);

    try {
      return JSON.parse(value);
    } catch (err) {
      // debug('[COOKIE]: this.get() encountered an error', err);
      return value || null;
    }
  }

  getAll() {
    return cookieHelper();
  }

  remove(name) {
    try {
      cookieHelper(name, null, { ...this._options
      });
      return true;
    } catch (err) {
      debug('[COOKIE]: this.remove() encountered an error', err);
      return false;
    }
  }

}
const cookie = new Cookie();

export { Cookie, cookie };
