/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuid } from 'uuid';
import { cookie } from '@turnercode/cdp-cookie';
import { findFrame } from './privacy';
import { cookiesEnabled, fromQueryString } from './utils/browser';
import { URLS } from './utils/constants';
import { debug } from './utils/logger';
import { sendRequest } from './utils/sendRequest';

export type WmukidValue =
  | string
  | {
      id: string;
      version: string;
      timestamp: string;
    };

export type CdpidValue = string | { cdpId: string; wmukId: string };

export let is3PCookiesEnabled = null;

const win = window as any;
const doc = document as any;

/**
 * Initializes the Prism identity module, which is responsible for:
 *   - Managing the WMUKID
 *   - Capturing third-party IDs
 *   - Sending data to Doppler
 *
 * @param core The Prism core payload and API manager
 * @param cb Function invoked after tracking an identity registration
 */
export function initIdentity(env: string): { wmukid: string; cdpid: string } {
  let wmukid: WmukidValue = '';
  let wmukidStable = '';
  let cdpid: CdpidValue = '';

  function isUUID(id: string) {
    const uuidV4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    const uuid = id.match(uuidV4Regex);
    if (uuid === null) {
      return false;
    }
    return true;
  }

  function searchURL(): string | null {
    const utm_term = fromQueryString('utm_term', win.location.href);
    const match = new RegExp(/cdpid_([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i).exec(
      utm_term
    );
    let cdpid = null;
    if (match) {
      cdpid = match[1];
    }
    return cdpid;
  }

  function updateCdpData(cdpId: string, wmukId: string) {
    const cdpidValid = isUUID(cdpId);
    if (!cdpidValid) {
      debug({
        message: `CDPID value ${JSON.stringify(cdpid)} is invalid. Returning without updating`,
        methodName: 'updateCdpData',
        eventType: 'cdpid',
      });
      return;
    }
    cdpId = searchURL() || cdpId; // If no prior CPDID on current page, update with CPDPID in url from inbrain
    cookie.set('CDPID', JSON.stringify({ cdpId, wmukId }), {
      samesite: 'None',
      secure: true,
      encode: false,
    });
  }

  // Add Third Party Cookie
  const thirdPartyIFrame = doc.createElement('iframe');
  thirdPartyIFrame.setAttribute('id', 'psm_thirdparty');
  thirdPartyIFrame.setAttribute('style', 'display:none');
  thirdPartyIFrame.setAttribute('src', URLS.lightningOrigin + URLS.thirdPartyCookie);
  doc.body.appendChild(thirdPartyIFrame);

  win.addEventListener(
    'message',
    (e: MessageEvent) => {
      if (e.origin === URLS.lightningOrigin) {
        if (e.data === 'PSM:3PCunsupported') {
          // Third party cookies are NOT supported
          is3PCookiesEnabled = false;
        } else if (e.data === 'PSM:3PCsupported') {
          // Third party cookies ARE supported
          is3PCookiesEnabled = true;
        } else {
          debug({
            message: `Updating CDPID from iFrame value: ${JSON.stringify(e.data)}`,
            methodName: 'initIdentity',
            eventType: 'message',
          });
          updateCdpData(e.data, wmukidStable);
        }
      }
    },
    false
  );

  if (cookiesEnabled()) {
    wmukid = cookie.get('WMUKID') as WmukidValue;
    wmukidStable = cookie.get('WMUKID_STABLE') as string;
    cdpid = cookie.get('CDPID') as CdpidValue;
    const cdpidValid = cdpid && typeof cdpid === 'object' && 'cdpId' in cdpid && isUUID(cdpid.cdpId);

    debug({
      message: `[PSM]: Cookies enabled. Retrieving ID values
WMUKID: ${JSON.stringify(wmukid)}
WMUKID_STABLE: ${wmukidStable}
CDPID: ${JSON.stringify(cdpid)}
`,
      methodName: 'initIdentity',
      eventType: 'identity',
    });

    if (wmukidStable == null) {
      if (wmukid == null) {
        // If the WMUKID does not exist, generate one, set a cookie, and track the newly-registered ID
        wmukidStable = uuid();
        debug({
          message: `WMUKID and WMUKID_STABLE cookies not found. Generating WMUKID_STABLE value: ${wmukidStable}`,
          methodName: 'initIdentity',
          eventType: 'identity',
        });
      } else if (typeof wmukid === 'object' && 'id' in wmukid) {
        // If a legacy WMUKID cookie exists, pull the ID value from it
        wmukidStable = wmukid.id;
        debug({
          message: `Legacy WMUKID value ${wmukid.id} found. Setting WMUKID_STABLE value: ${wmukidStable}`,
          methodName: 'initIdentity',
          eventType: 'identity',
        });
      } else {
        wmukidStable = wmukid;
        debug({
          message: `WMUKID string value ${wmukid} found. Setting WMUKID_STABLE value: ${wmukidStable}`,
          methodName: 'initIdentity',
          eventType: 'identity',
        });
      }
    }

    debug({
      message: `Setting WMUKID_STABLE cookie to ${wmukidStable}. Cookie options: ${JSON.stringify(cookie.options())}`,
      methodName: 'initIdentity',
      eventType: 'identity',
    });
    cookie.set('WMUKID_STABLE', wmukidStable);

    if (cdpid === null || !cdpidValid) {
      if (findFrame('prism_toolkit') === null) {
        const iframe = doc.createElement('iframe');
        iframe.setAttribute('id', 'prism_toolkit');
        iframe.setAttribute('style', 'display:none');
        iframe.setAttribute('src', URLS.lightningOrigin + URLS.iFramePath[env]);
        doc.body.appendChild(iframe);
      }
    } else if (typeof cdpid === 'object' && 'cdpId' in cdpid && 'wmukId' in cdpid && cdpidValid) {
      const cdpidStr: string = cdpid.cdpId;
      const wmukidStr: string = cdpid.wmukId;

      if (wmukidStable !== wmukidStr) {
        debug({
          message: `Updating CDPID value ${cdpidStr} with WMUKID: ${wmukidStable}`,
          methodName: 'initIdentity',
          eventType: 'cdpid',
        });
        updateCdpData(cdpidStr, wmukidStable);
      } else {
        debug({
          message: `CDPID cookie has already been set to value ${JSON.stringify(
            cdpid
          )}. To start a new test, delete the CDPID cookie on this domain and lightning.warnermediacdn.com.`,
          methodName: 'initIdentity',
          eventType: 'cdpid',
        });
      }
    }
  }

  return { wmukid: wmukidStable, cdpid: cdpid as string };
}

export async function initIdentityAsync(env: string): Promise<{ wmukid: string; cdpid: string }> {
  return new Promise((resolve) => {
    const ids = initIdentity(env);
    // Resolve immediately for automated test since useFakeTimers/setTimeout is causing timeout error
    if (env === 'AUTOMATED_TEST') {
      return resolve({ wmukid: ids.wmukid, cdpid: ids.cdpid });
    }
    let counter = 0;
    (function waitForCdpId() {
      ids.cdpid = cookie.get('CDPID') as string;
      if (ids.cdpid || counter >= 20) {
        return resolve({ wmukid: ids.wmukid, cdpid: ids.cdpid });
      }
      counter++;
      setTimeout(waitForCdpId, 50);
    })();
  });
}
