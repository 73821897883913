import { v4 as uuid } from 'uuid';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { cookie, findTopDomain } from '@turnercode/cdp-cookie';
import { FeatureFlagClient, IQueryFeatureResult } from '@turnercode/cdp-feature-flag-client';
import Queue from '@segment/localstorage-retry';
import { initIdentityAsync, WmukidValue, CdpidValue } from './identity';
import { initInbrain } from './inbrain';
import {
  PayloadCore,
  payloadCore,
  SessionProperties,
  Ids,
  UserConsentEventDetails,
  AdsProperties,
  ConsentProperties,
  Device,
  Library,
  LocationProperties,
  NavigationProperties,
} from './payloadCore';
// import { initPrivacy, USPData, uspString } from './privacy';
import { initAdViewListeners, setAdViewMetrics, resetAdViewMetrics, registeredSlots } from './adViewability';
import { CCPA_LOCATIONS, URLS, MAX_SESSION_DURATION, featureFlagDefaults, queueOptions } from './utils/constants';
import { fromQueryString, getReferrer, setBrowser } from './utils/browser';
import {
  getAdsGUID,
  getAdsTransactionId,
  getAttUuid,
  getCnnUid,
  getConvivaId,
  getEcid,
  getKruxId,
  getOptanonConsentCookie,
  getZionId,
  hydratePayload,
} from './utils/hydratePayload';
import { processQueue } from './utils/processQueue';
import { sendRequest } from './utils/sendRequest';
import { createLogger, error, warn, info, debug } from './utils/logger';
import pkg from '../package.json';
import { checkOutsideUS } from './privacy';
import { initEngagementEvents, setEngagementMetrics, resetHbInterval } from './engagement';
import { initHeartbeat } from './heartbeat';

export interface PsmConfig {
  iab_categories?: [];
  platform?: string;
  brand: string;
  subBrand?: string;
  psmEnvironment: string;
  cookieDomain?: string;
  cookieSameSite?: string;
  cookieSecure?: boolean;
  cookieExpires?: number;
  countryCode?: string;
  topics?: {
    page: string[];
    video: string[];
  };
  contentMetadata?: {
    page: any[];
    video: any[];
  };
  inbrainBetaURL?: string;
}

export interface Geolocation {
  asn: {
    id: string;
    name: string;
  };
  continent: string;
  continentName: string;
  country: string;
  country_alpha2: string;
  country_alpha3: string;
  ip_address: string;
  lat?: string;
  lon?: string;
  proxy: null | string;
  states: {
    cities: string[];
    counties: string[];
    state: string;
    zipcodes: string[];
  }[];
}

export interface AuthTokenResolveRequest {
  wmukid: string;
  brand: string;
  ids: Ids;
  consentProperties: ConsentObject;
  authentication: AuthObject[];
}

export interface ConsentObject {
  usp: string;
}

export interface AuthObject {
  provider: string;
  details: AuthDetailsObject;
}

export interface AuthDetailsObject {
  id: string;
  url?: string;
}

export interface AuthTokenResolveResponse {
  tokens: AuthServiceTokenObject[];
}

export interface AuthServiceTokenObject {
  provider: string;
  token: string;
  expiration: string;
  refreshToken?: string;
}

export interface IdResolveResponse {
  wmhhid?: string;
  wminid?: string;
  wmsegs?: string;
  hhidVersion?: number;
  wmResolvedUserData: { [key: string]: any };
}

const win = window as any;
const doc = win.document as any;

export class Psm {
  /**
   * Flag set to true when Prism has initialized
   */
  ready = false;

  /**
   * Default values from Prism config
   */
  config: PsmConfig = {
    platform: 'web',
    brand: '',
    subBrand: '',
    psmEnvironment: undefined,
    cookieSameSite: 'Lax',
    cookieSecure: false,
    cookieExpires: 31536000000, // 1 year in miliseconds
  };

  /**
   * Feature flag client instance
   */
  ffClient: FeatureFlagClient;

  /**
   * Feature flags
   */
  flags: IQueryFeatureResult[] = [];

  /**
   * Request queue for Doppler privacy API
   */
  privacyQueue: Queue;

  /**
   * Request queue for Doppler identity API
   */
  identityQueue: Queue;

  /**
   * Request queue for Doppler telemetry API
   */
  telemetryQueue: Queue;

  /**
   * Geolocation API response
   */
  location: Partial<Geolocation> = {
    asn: {
      id: '',
      name: '',
    },
    continent: '',
    continentName: '',
    country: '',
    country_alpha2: '',
    country_alpha3: '',
    ip_address: '',
    lat: '',
    lon: '',
    proxy: null,
    states: [
      {
        cities: [],
        counties: [],
        state: '',
        zipcodes: [],
      },
    ],
  };

  /**
   * WMUKID from cookie or generated when Prism is initialized
   */
  wmukid: WmukidValue = null;

  /**
   * CDPID from cookie or generated when Prism is initialized
   */
  cdpid: CdpidValue = null;

  /**
   * Country code value for location-guarded logic
   */
  countryCode: string;

  /**
   * Session properties as determine on initialization
   */
  session: SessionProperties;

  /**
   * User's browser -- currently only sets to Safari or Unknown
   */
  browser: string;

  /**
   * Consent Rule to track how Prism was loaded based on user consent
   */
  consentRule = 'Other';

  /**
   * Consent categories used loading Prism for non-US users
   */
  consentCategories: Record<string, boolean>;

  constructor() {
    win.WM = win.WM || {};
    win.WM.PSM = win.WM.PSM || {};
    win.WM.PSM.initPsm = this.initPsm.bind(this);
    win.WM.PSM.getVersion = this.getVersion.bind(this);
    win.WM.PSM.getWMUKID = this.getWMUKID.bind(this);
    win.WM.PSM.getCDPID = this.getCDPID.bind(this);
    win.WM.PSM.getConfig = this.getConfig.bind(this);
    win.WM.PSM.getFlags = this.getFlags.bind(this);
    win.WM.PSM.getAdsProperties = this.getAdsProperties.bind(this);
    win.WM.PSM.getConsentProperties = this.getConsentProperties.bind(this);
    win.WM.PSM.getBrand = this.getBrand.bind(this);
    win.WM.PSM.getDeviceProperties = this.getDeviceProperties.bind(this);
    win.WM.PSM.getIds = this.getIds.bind(this);
    win.WM.PSM.getLibrary = this.getLibrary.bind(this);
    win.WM.PSM.getLocationProperties = this.getLocationProperties.bind(this);
    win.WM.PSM.getDeviceProperties = this.getDeviceProperties.bind(this);
    win.WM.PSM.getNavigationProperties = this.getNavigationProperties.bind(this);
    win.WM.PSM.getSessionProperties = this.getSessionProperties.bind(this);
    win.WM.PSM.isDopplerIdentityOnStartEnabled = () => this.queryFlag('doppler-identity-onstart');
    win.WM.PSM.isDopplerIdentityOnCompleteEnabled = () => this.queryFlag('doppler-identity-oncomplete');
    win.WM.PSM.isDopplerPrivacyEnabled = () => this.queryFlag('doppler-privacy');
    win.WM.PSM.isDopplerTelemetryEnabled = () => this.queryFlag('doppler-telemetry');
  }

  async initPsm(config: PsmConfig): Promise<void> {
    if (this.ready) return;
    this.config = Object.assign(this.config, config);

    createLogger(this);

    if (typeof config === 'undefined') {
      const err = new Error('[PSM]: Please provide a valid configuration to initPsm');
      error({
        err,
        eventType: 'configValidation',
        methodName: 'initPsm',
      });
      throw err;
    }

    const init = async () => {
      const env = config.psmEnvironment.toUpperCase();
      const core: PayloadCore = payloadCore();

      // this.privacyQueue = new Queue('privacy', queueOptions, processQueue(URLS.privacy[env]));
      this.identityQueue = new Queue('identity', queueOptions, processQueue(URLS.identity[env]));
      // this.telemetryQueue = new Queue('telemetry', queueOptions, processQueue(URLS.telemetry[env]));

      if (win.location !== window.parent.location) {
        // detecting if Prism is loading from iFrame
        console.log('[PSM]: Failing to load Prism from iFrame');
        return;
      }

      // initPrivacy(config.enablePrivacy, this.countryCode);
      const ids = await initIdentityAsync(env);

      this.wmukid = ids.wmukid;
      this.cdpid = ids.cdpid;

      // Set session properties with initial page load parameter
      this.setSessionProperties(true);

      // Resolve IDs
      this.resolveIds();

      // Set sendAuthToken cookie
      this.setSendAuthToken();

      // Set sendHHID cookie
      this.setSendHHIDCookie();

      // Set isInAuthTokenExperiment cookie
      this.setIsInExperiment('isInAuthTokenExperiment');

      // Set isInHHIDExperiment cookie
      this.setIsInExperiment('isInHHIDExperiment');

      // Set sendWMSegs cookie
      this.setSendWMSegs();

      // resolve auth token
      this.resolveAuthTokens(core);

      hydratePayload(this, core);

      // Publish to ZionMessageBus
      if (win.ZionMessageBus) {
        win.ZionMessageBus.getInstance().publish('id_found', { type: 'wmukid', value: this.wmukid });
      }

      core.trackIdentityRegistration('identity on page start', new Date().toISOString(), (data) => {
        if (this.queryFlag('doppler-identity-onstart')) {
          this.identityQueue.addItem(data);
          this.resetNewSessionFields();
        }

        if (env === 'AUTOMATED_TEST') {
          win.localStorage.setItem('payload-on-start', JSON.stringify(data));
        }
      });
      win.addEventListener('load', () => {
        this.setSessionProperties();
        hydratePayload(this, core);
        core.trackIdentityRegistration('identity on page complete', new Date().toISOString(), (data) => {
          if (this.queryFlag('doppler-identity-oncomplete')) {
            this.identityQueue.addItem(data);

            if (env === 'AUTOMATED_TEST') {
              win.localStorage.setItem('payload-on-complete', JSON.stringify(data));
            }
            this.resetNewSessionFields();
          }
        });
      });
      doc.addEventListener('userConsentChanged', (data: { detail: UserConsentEventDetails }) => {
        debug({
          message: 'userConsentChanged event received',
          methodName: 'initPsm',
          eventType: 'privacy',
          context: { eventDetails: data.detail },
        });
        this.setSessionProperties();
        hydratePayload(this, core);
        core.trackConsentUpdated('userConsentChanged', data.detail, new Date().toISOString(), (data) => {
          if (this.queryFlag('doppler-consent-update')) {
            debug({
              message: 'Consent Update event registered',
              methodName: 'initPsm',
              eventType: 'privacy',
              context: { payload: data },
            });
            this.identityQueue.addItem(data);
            this.resetNewSessionFields();
          }
        });
      });

      if (this.queryFlag('doppler-heartbeat-event')) {
        initAdViewListeners();
        initEngagementEvents();
        // Send event only if wmukid is not unknown
        if (this.wmukid !== 'Unknown') {
          const sendPageExitHB = () => {
            setEngagementMetrics();
            hydratePayload(this, core);
            core.trackPageExit('visibilitychange', new Date().toISOString(), (data) => {
              data.sentAtTimestamp = new Date().toISOString();
              win.navigator.sendBeacon(URLS.identity[env], JSON.stringify(data));
            });
          };
          // heartbeat for tracking total time
          initHeartbeat(() => {
            setAdViewMetrics();
            setEngagementMetrics();
            this.setSessionProperties();
            hydratePayload(this, core);
            core.trackHeartbeat(new Date().toISOString(), (data) => {
              this.identityQueue.addItem(data);
              this.resetNewSessionFields();
              resetHbInterval();
              resetAdViewMetrics();
            });
          }, sendPageExitHB);

          // Add event listeners for pageExit
          win.addEventListener('beforeunload', () => {
            setEngagementMetrics();
            hydratePayload(this, core);
            core.trackPageExit('beforeunload', new Date().toISOString(), (data) => {
              data.sentAtTimestamp = new Date().toISOString();
              win.navigator.sendBeacon(URLS.identity[env], JSON.stringify(data));
            });
          });

          win.addEventListener('pagehide', (event) => {
            if (!event.persisted) {
              setEngagementMetrics();
              hydratePayload(this, core);
              core.trackPageExit('pagehide', new Date().toISOString(), (data) => {
                data.sentAtTimestamp = new Date().toISOString();
                win.navigator.sendBeacon(URLS.identity[env], JSON.stringify(data));
              });
            }
          });
        }
      }

      if (this.queryFlag('doppler-pubsub-event')) {
        for (const type in config.topics) {
          config.topics[type].forEach((topic: string) => {
            win.PubSub &&
              win.PubSub.subscribe(topic, (...args: any[]) => {
                let data = typeof args[1] === 'object' ? args[1] : args[0];
                data = Object.keys(data).length < 3 && data.video ? data.video : data;
                win.psmPubSubData = win.psmPubSubData || {};
                win.psmPubSubData[type] = win.psmPubSubData[type] || {};
                Object.assign(win.psmPubSubData[type], data);
                hydratePayload(this, core);
                core.trackPubSub(topic, new Date().toISOString(), (data) => {
                  this.identityQueue.addItem(data);
                  this.resetNewSessionFields();
                });
              });
          });
        }
      }

      // this.privacyQueue.start();
      this.identityQueue.start();
      // this.telemetryQueue.start();

      try {
        initInbrain(this, core);
      } catch (err) {
        // add logging
        error({
          err,
          eventType: 'inbrain',
          methodName: 'initInbrain',
        });
      }

      this.ready = true;
    };

    this.validateConfig(config);

    this.countryCode =
      this.hasProperty('countryCode', config) &&
      typeof config.countryCode !== 'undefined' &&
      this.config.countryCode.length
        ? this.config.countryCode.toUpperCase()
        : '';

    if (CCPA_LOCATIONS.includes(this.countryCode) || this.countryCode === '') {
      try {
        this.location = await sendRequest(URLS.locate);
        this.countryCode = this.countryCode === '' ? this.location.country_alpha2 : this.countryCode;
      } catch (err) {
        error({
          err,
          methodName: 'sendRequest',
          eventType: 'geolocation',
        });
        this.location.country = undefined;
        this.location.country_alpha2 = undefined;
      }
    }

    try {
      const { shouldLoad, categories } = await checkOutsideUS();
      if (CCPA_LOCATIONS.includes(this.countryCode)) {
        this.consentRule = 'US';
      } else if (shouldLoad && categories.length > 0) {
        this.consentRule = 'GDPR';
      }
      this.consentCategories = categories.reduce((acc, obj) => {
        return {
          ...acc,
          ...obj,
        };
      }, {});
    } catch (err) {
      console.log('[PSM]: Error encountered during location check', err);
      this.consentCategories = {};
    }

    cookie.options({
      domain: this.config.cookieDomain,
      maxage: this.config.cookieExpires,
      path: '/',
      samesite: this.config.cookieSameSite,
      secure: this.config.cookieSecure,
    });

    this.browser = setBrowser();
    const clientId = `psmFFClient-${this.config.brand}-${this.config.subBrand}`;

    this.ffClient = new FeatureFlagClient({
      configUrl: URLS.featureFlag[config.psmEnvironment.toUpperCase()],
      configRefreshInterval: 60000,
      userTargetingProperties: {
        Platform: ['Web'],
        Brand: [config.brand],
        Browser: this.browser,
        ClientID: clientId,
        CountryCode: this.countryCode,
      },
      clientId: clientId,
    });

    try {
      const flags = await this.ffClient.queryAllFeatureFlags();
      this.flags = flags.results;
      // Check Outside US feature flag
      if (this.consentRule === 'US' || (this.consentRule === 'GDPR' && this.queryFlag('outside-us-location-check'))) {
        await init();
      }
    } catch (err) {
      error({
        err,
        methodName: 'queryAllFeatureFlags',
        eventType: 'featureFlagging',
      });
      await init();
    }
  }

  setSendHHIDCookie() {
    const sendHHID = this.queryFlag('sendHHID');
    return cookie.set('sendHHID', sendHHID);
  }

  setSendAuthToken() {
    const sendAuthToken = this.queryFlag('sendAuthToken');
    return cookie.set('sendAuthToken', sendAuthToken);
  }

  setIsInExperiment(name: string) {
    const value = this.queryFlag(name);
    return cookie.set(name, value);
  }

  setSendWMSegs() {
    const sendWMSegs = this.queryFlag('sendWMSegs');
    return cookie.set('sendWMSegs', sendWMSegs);
  }

  async resolveIds() {
    // Confirm IDR service is enabled
    if (!this.queryFlag('idresolve')) {
      debug({
        message: '[PSM]: idresolve flag is disabled',
        methodName: 'resolveIds',
        eventType: 'idresolution',
      });
      return false;
    }

    // Check if previous IDR request happened within 24 hours
    const idrTimestampCookie = cookie.get('idrTimestamp') as string | null;
    if (idrTimestampCookie) {
      const idrTimestamp = new Date(idrTimestampCookie);
      const currentTimestamp = new Date();
      const idrLifespan = (currentTimestamp.getTime() - idrTimestamp.getTime()) / 1000 / 60 / 60; // Get lifespan in hours

      if (idrLifespan < 24) {
        info({
          message: `[PSM]: IDR lifespan, ${idrLifespan}, less than 24 hours`,
          methodName: 'resolveIds',
          eventType: 'idresolution',
        });
        return false;
      }
    }
    const idrRequestBody = {
      wmukid: this.getWMUKID(),
      // TODO: Replace with abstracted `getThirdPartyIds` method when it exists
      ids: {
        attuuid: getAttUuid(),
        cdpid: this.getCDPID(),
        cnnuid: getCnnUid(),
        convivaid: getConvivaId(),
        ecid: getEcid(),
        kruxid: getKruxId(),
        wmhhid: cookie.get('wmhhid'),
        wminid: cookie.get('wminid'),
        zionid: getZionId(),
      },
    };

    // Make IDR request
    try {
      const idrResponse: IdResolveResponse = await sendRequest(URLS.idresolve[this.config.psmEnvironment], {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(idrRequestBody),
      });
      const { wmhhid, wminid, wmsegs, hhidVersion } = idrResponse;

      if (!wmhhid) {
        cookie.remove('wmhhid');
      }

      if (!wmsegs) {
        localStorage.removeItem('wmsegs');
      }

      wmhhid && cookie.set('wmhhid', wmhhid);
      hhidVersion && cookie.set('hhidVersion', hhidVersion.toString());
      wminid && cookie.set('wminid', wminid);
      wmsegs && localStorage.setItem('wmsegs', wmsegs);
    } catch (err) {
      debug({
        err,
        eventType: 'idresolution',
        methodName: 'sendRequest',
      });
    }
    // Set IDR timestamp regardless of IDR response
    cookie.set('idrTimestamp', new Date());

    return true;
  }

  async resolveAuthTokens(core: PayloadCore) {
    // Confirm auth service is enabled
    if (!this.queryFlag('auth-service')) {
      debug({
        message: '[PSM]: auth-service flag is disabled',
        methodName: 'resolveAuthTokens',
        eventType: 'authService',
      });
      return false;
    }
    let newToken = true;
    let tokenUpdated = false;

    // Check if previous auth service request happened within 24 hours
    const resolveAuthTimestampCookie = cookie.get('resolveAuthTimestamp') as string | null;
    if (resolveAuthTimestampCookie) {
      const authTimestamp = new Date(resolveAuthTimestampCookie);
      const authLifespan = (Date.now() - authTimestamp.getTime()) / 1000 / 60 / 60; // Get lifespan in hours

      if (authLifespan < 24) {
        info({
          message: `Resolve Auth Token lifespan, ${authLifespan}, less than 24 hours`,
          methodName: 'resolveAuthTokens',
          eventType: 'authService',
        });
        return false;
      }
    }

    // Generate Liveramp Object
    let liverampObject;
    let zetaObject;
    let brazeObject;
    let cnnAuthObject;
    let tradeDeskObject;
    const tok_lr_cookie = cookie.get('tok_lr') as string;
    const lr_env_cookie = cookie.get('_lr_env') as string;
    const tok_ttuid_cookie = cookie.get('tok_ttuid') as string;

    const liverampEnv = lr_env_cookie ? lr_env_cookie : tok_lr_cookie;

    // Add Liveramp obj once
    if (liverampEnv) {
      liverampObject = {
        provider: 'Liveramp ATS',
        details: {
          id: liverampEnv,
        },
      };
      newToken = false;
      tokenUpdated = true;
    }

    // Generate Zeta Object

    // Confirm Parameters are present
    const bt_ts = fromQueryString('bt_ts', win.location.href);
    const utm_term = fromQueryString('utm_term', win.location.href);

    if (bt_ts && utm_term) {
      // Check if bt_ts is less than 24 hours old
      const btLifespan = (Date.now() - parseInt(bt_ts)) / 1000 / 60 / 60;

      if (btLifespan > 24) {
        info({
          message: `bt_ts Parameter lifespan, ${btLifespan}, greater than 24 hours`,
          methodName: 'resolveAuthTokens',
          eventType: 'authService',
        });
        return false;
      }

      zetaObject = {
        provider: 'zeta',
        details: {
          id: utm_term,
        },
      };
    }

    // Generate Braze Object
    const primo = fromQueryString('primo', win.location.href);
    if (primo) {
      brazeObject = {
        provider: 'braze',
        details: {
          id: primo,
        },
      };
    }

    // Generate CNN Auth Object
    const cnnUid = getCnnUid();
    if (cnnUid) {
      cnnAuthObject = {
        provider: 'cnnAuthentication',
        details: {
          id: cnnUid,
        },
      };
    }

    if (tok_ttuid_cookie) {
      tradeDeskObject = {
        provider: 'tradedesk',
        details: {
          id: tok_ttuid_cookie,
        },
      };
    }

    // Return if neither LR envelope or Zeta info is valid
    if (!liverampEnv && (!utm_term || !bt_ts) && !primo && !cnnUid) {
      debug({
        message: '[PSM]: No token information present',
        methodName: 'resolveAuthTokens',
        eventType: 'authService',
      });
      return false;
    }

    // Compile all auth objects
    const authObjects = [];
    if (liverampObject?.provider) {
      authObjects.push(liverampObject);
    }
    if (zetaObject?.provider) {
      authObjects.push(zetaObject);
    }
    if (brazeObject?.provider) {
      authObjects.push(brazeObject);
    }
    if (cnnAuthObject?.provider) {
      authObjects.push(cnnAuthObject);
    }

    if (tradeDeskObject?.provider) {
      authObjects.push(tradeDeskObject);
    }

    const authTokenRequestBody: AuthTokenResolveRequest = {
      wmukid: this.getWMUKID(),
      brand: this.config.brand,
      ids: {
        attuuid: getAttUuid(),
        cdpid: this.getCDPID(),
        convivaid: getConvivaId(),
        ecid: getEcid(),
        kruxid: getKruxId(),
        wmhhid: cookie.get('wmhhid') as string,
        wminid: cookie.get('wminid') as string,
        zionid: getZionId(),
      },
      consentProperties: { usp: cookie.get('usprivacy') as string },
      authentication: authObjects,
    };

    // Make IDR request
    try {
      const authResponse: AuthTokenResolveResponse = await sendRequest(URLS.authSvc[this.config.psmEnvironment], {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(authTokenRequestBody),
      });
      const { tokens } = authResponse;
      const liverampToken: AuthServiceTokenObject = tokens.filter((token) => token.provider === 'liveramp')[0];
      cookie.set('tok_lr', liverampToken.token, {
        expires: new Date(liverampToken.expiration),
      });
      if (lr_env_cookie && !tok_lr_cookie) {
        cookie.remove('_lr_env');
      }

      const tradeDeskToken: AuthServiceTokenObject = tokens.filter((token) => token.provider === 'tradedesk')[0];
      cookie.set('tok_ttuid', tradeDeskToken.token, {
        expires: new Date(tradeDeskToken.expiration),
      });
      cookie.set('tok_ttuid_refresh', tradeDeskToken.refreshToken, {
        expires: new Date(tradeDeskToken.expiration),
      });
    } catch (err) {
      debug({
        err,
        eventType: 'authService',
        methodName: 'resolveAuthTokens',
      });
    }

    this.setSessionProperties();
    hydratePayload(this, core);
    if (newToken) {
      core.trackTokenEvent('new token', new Date().toISOString(), (data) => {
        if (this.queryFlag('doppler-send-token-event')) {
          this.identityQueue.addItem(data);
          this.resetNewSessionFields();
        }
      });
    }

    if (tokenUpdated) {
      core.trackTokenEvent('token updated', new Date().toISOString(), (data) => {
        if (this.queryFlag('doppler-send-token-event')) {
          this.identityQueue.addItem(data);
          this.resetNewSessionFields();
        }
      });
    }

    // Set IDR timestamp regardless of IDR response
    cookie.set('resolveAuthTimestamp', new Date());

    return true;
  }

  getPageLoadId(sessionStart, initialPageLoad) {
    const pageLoadIdCookie = cookie.get('psmPageLoadId') as string | null;
    const pageLoadIdInt = pageLoadIdCookie && parseInt(pageLoadIdCookie);
    let pageLoadId = pageLoadIdInt;
    if (sessionStart) {
      pageLoadId = 1;
    }
    if (!sessionStart && initialPageLoad && pageLoadIdInt) {
      pageLoadId = pageLoadIdInt + 1;
    }
    return pageLoadId;
  }

  setSessionProperties(initialPageLoad = false) {
    const currentTimestamp = new Date();
    let sessionProperties: SessionProperties = {
      isSessionStart: true,
      pageloadid: this.getPageLoadId(true, initialPageLoad),
      psmLastActiveTimestamp: currentTimestamp.toISOString(),
      psmSessionStart: currentTimestamp.toISOString(),
      sessionDuration: 0,
      sessionid: uuid(),
    };

    // Get all stored session info
    const prevSessionId = cookie.get('psmSessionId') as string | null;
    const prevSessionStartCookie = cookie.get('psmSessionStart') as string | null;
    const prevLastActiveTimestampCookie = cookie.get('psmLastActiveTimestamp') as string | null;

    if (prevSessionId !== null) {
      const prevLastActiveTimestamp = new Date(prevLastActiveTimestampCookie);
      const prevSessionStartTimestamp = new Date(prevSessionStartCookie);
      const timeSinceLastActivity = currentTimestamp.getTime() - prevLastActiveTimestamp.getTime(); // time past in miliseconds

      // if session >max time, then create new session
      if (timeSinceLastActivity > MAX_SESSION_DURATION) {
        info({
          message: `[PSM]: Session ${prevSessionId} timed out after ${timeSinceLastActivity / 1000} seconds.`,
          methodName: 'setSessionProperties',
          eventType: 'session',
        });
        sessionProperties = {
          ...sessionProperties,
          previousSession: {
            psmLastActiveTimestamp: prevLastActiveTimestampCookie,
            psmSessionStart: prevSessionStartCookie,
            sessionDuration: (prevLastActiveTimestamp.getTime() - prevSessionStartTimestamp.getTime()) / 1000,
            sessionid: prevSessionId,
          },
        };
        // If session <max time, update psmLastActive timestamp
      } else {
        debug({
          message: `[PSM]: Session ${prevSessionId} still active after ${
            timeSinceLastActivity / 1000
          } seconds. Updating last active timestamp.`,
          methodName: 'setSessionProperties',
          eventType: 'session',
        });
        sessionProperties = {
          isSessionStart: false,
          pageloadid: this.getPageLoadId(false, initialPageLoad),
          psmLastActiveTimestamp: currentTimestamp.toISOString(),
          psmSessionStart: prevSessionStartCookie,
          sessionDuration: (currentTimestamp.getTime() - prevSessionStartTimestamp.getTime()) / 1000,
          sessionid: prevSessionId,
        };
      }
    } else {
      info({
        message: `[PSM]: Creating new session`,
        methodName: 'setSessionProperties',
        eventType: 'session',
      });
    }

    // Set all session cookie values
    cookie.set('psmSessionId', sessionProperties.sessionid);
    cookie.set('psmLastActiveTimestamp', sessionProperties.psmLastActiveTimestamp);
    cookie.set('psmSessionStart', sessionProperties.psmSessionStart);
    cookie.set('psmPageLoadId', JSON.stringify(sessionProperties.pageloadid));

    this.session = sessionProperties;
    return;
  }

  // Clears isSessionStart/previousSession properties
  // Called after each queue addition so it's only cleared after sending to Doppler
  resetNewSessionFields() {
    this.session = {
      ...this.session,
      isSessionStart: false,
      previousSession: null,
    };
  }

  getVersion() {
    return pkg.version;
  }

  getWMUKID() {
    return (cookie.get('WMUKID_STABLE') as string) || 'Unknown';
  }

  getCDPID() {
    const data = cookie.get('CDPID') as CdpidValue;

    if (data && typeof data === 'object' && 'cdpId' in data && 'wmukId' in data) {
      return data.cdpId;
    }
    return (data as string) || 'Unknown';
  }

  getConfig() {
    return this.config;
  }

  getFlags() {
    return this.flags.reduce((flagObj, curr) => ({ ...flagObj, [curr.flagId]: curr }), {});
  }

  getAdsProperties(): AdsProperties {
    return {
      guid: getAdsGUID() || '',
      transid: getAdsTransactionId() || '',
      ads: registeredSlots,
    };
  }

  getConsentProperties(): ConsentProperties {
    return {
      uspString: cookie.get('usprivacy') as string,
      consentRule: this.consentRule,
      optanonConsent: getOptanonConsentCookie() || '',
    };
  }

  getBrand(): string {
    return this.config.brand;
  }

  getDeviceProperties(): Device {
    return {
      type: win.navigator.platform,
      userAgent: win.navigator.userAgent,
    };
  }

  getIds(): Ids {
    return {
      attuuid: getAttUuid() || '',
      cdpid: this.getCDPID(),
      cnnuid: getCnnUid() || '',
      convivaid: getConvivaId() || '',
      ecid: getEcid() || '',
      kruxid: getKruxId() || '',
      liverampatsid: cookie.get('tok_lr') as string,
      wmhhid: cookie.get('wmhhid') as string,
      wminid: cookie.get('wminid') as string,
      zionid: getZionId() || '',
    };
  }

  getLibrary(): Library {
    return {
      name: 'Prism JS',
      version: pkg.version,
      initConfig: this.config,
    };
  }

  getLocationProperties(): LocationProperties {
    return {
      city: this.location.states[0].cities[0],
      state: this.location.states[0].state,
      country: this.location.country,
      zip: this.location.states[0].zipcodes[0],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
      locale: win.navigator.language,
      language: win.navigator.language.split('-')[0] || null,
    };
  }

  getNavigationProperties(): NavigationProperties {
    return {
      url: win.location.href,
      rootDomain: findTopDomain(win.location.href),
      referrer: getReferrer(),
      path: win.location.pathname,
      search: win.location.search,
      title: doc.title,
    };
  }

  getSessionProperties(): SessionProperties {
    return this.session;
  }

  queryFlag(flagId: string): boolean {
    let flagEnabled: boolean = featureFlagDefaults[flagId] || false;
    try {
      const { enabled } = this.flags.find((flag) => {
        return flag.flagId === flagId;
      });

      flagEnabled = enabled;
    } catch (err) {
      warn({
        err,
        methodName: 'queryFlag',
        eventType: 'featureFlagging',
      });
    }

    return flagEnabled;
  }

  private validateConfig(config: Partial<PsmConfig>) {
    const errors: string[] = [];

    if (!this.hasProperty('psmEnvironment', config)) errors.push('Please specify your psmEnvironment.');
    if (!this.hasProperty('brand', config)) errors.push('Please specify your brand.');

    if (errors.length) {
      const err = new Error(['[PSM]: Invalid configuration provided.'].concat(errors).join('\n'));

      error({
        err,
        methodName: 'validateConfig',
        eventType: 'configValidation',
      });
      throw err;
    }
  }

  private hasProperty(prop: string, obj: any) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
}
