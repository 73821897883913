import fetch from 'unfetch';
import { v4 } from 'uuid';

function getCurrentTime() {
  return new Date().toISOString();
}

/**
 * helper function for http methods ie. GET, POST, etc.
 * @param request
 * @returns request
 */
async function makeRequest(request) {
  const headers = {
    'Content-Type': 'application/json',
    ...request.headers
  };
  const response = await fetch(request.url, {
    method: request.method,
    headers,
    body: extractRequestPayload(request)
  });

  if (response.ok) {
    return extractResponsePayload(response);
  }

  const error = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}

function extractRequestPayload(request) {
  if (typeof request.payload === 'string') {
    return request.payload;
  } else if (request.payload instanceof String) {
    return request.payload.valueOf();
  } else {
    return JSON.stringify(request.payload);
  }
}

async function extractResponsePayload(response) {
  let headers = ''; // Matches the headers structure from XMLHttpRequest.getAllResponseHeaders() used in older versions of Utils

  if (response.headers) {
    response.headers.entries().forEach(entry => headers += `${entry[0]}: ${entry[1]}\r\n`);
  }

  const responsePayload = {
    status: response.status,
    statusText: response.statusText,
    headers: headers,
    data: '' // Matches previous defaulting logic

  }; // Must use .json() to extract payload body

  try {
    const responseJson = await response.json();
    responsePayload.data = responseJson; // Must have catch to prevent unhandled exceptions
  } catch (e) {
  }

  return responsePayload;
}

function isEnvNode() {
  try {
    return typeof process !== 'undefined' && typeof process.versions.node !== 'undefined';
  } catch (error) {
    return false;
  }
}
function generateUUID() {
  return v4();
}

//@ts-nocheck

function getGeoLocation() {
  return new Promise((resolve, reject) => {
    const request = {
      url: 'https://geo.ngtv.io/locate',
      method: 'GET'
    };
    makeRequest(request).then(response => resolve(response.data)).catch(error => {
      // TODO - need to revisit once logger will finalise
      // errorLogger(error);
      return reject(error);
    });
  });
}

/* eslint-disable @typescript-eslint/no-explicit-any */
class Logger {
  constructor(url, metadata) {
    this.url = void 0;
    this.metadata = void 0;
    this.level = void 0;
    this.url = url;
    this.metadata = metadata;
    this.level = window.location.search.search(/[?&]psm_debug=[1t]/) !== -1 ? 'debug' : 'error';
  }

  transport(level, scope, caller, ...args) {
    const payload = {
      message: {
        version: this.metadata.version,
        level,
        appId: this.metadata.appId,
        wmukid: this.metadata.wmukid,
        eventId: generateUUID(),
        timestamp: new Date().toISOString(),
        brand: this.metadata.brand,
        domain: this.metadata.domain,
        userAgent: window.navigator.userAgent.toString(),
        platform: 'Web',
        scope,
        caller,
        data: args
      }
    };
    makeRequest({
      url: this.url,
      method: 'POST',
      payload: JSON.stringify(payload, null, 2),
      headers: {
        type: 'application/json'
      }
    });
  }

  debug(scope, caller, ...args) {
    if (this.level !== 'debug') {
      return;
    }

    console.info('[PSM]', scope, caller, ...args);
    this.transport('debug', scope, caller, ...args);
  }

  error(scope, caller, ...args) {
    if (this.level === 'debug') {
      console.error('[PSM]', scope, caller, ...args);
    }

    this.transport('error', scope, caller, ...args);
  }

}

export { Logger as L, generateUUID as a, getCurrentTime as b, extractRequestPayload as e, getGeoLocation as g, isEnvNode as i, makeRequest as m };
