var config = {
	ffLibraryVersionCode: 1,
	ffLibraryVersionName: "1.0.2-beta",
	ffLibraryLanguage: "Javascript",
	minimumPollFrequencySeconds: 60
};

const APP_USER_ID = 'wmAppUserId';
const FEATURE_FLAG_CONFIG = 'wmFeatureFlagConfig';
const FEATURE_FLAG_USER_ID = 'wmFeatureFlagUserId';
const FEATURE_FLAG_CONFIG_ETAG = 'wmFeatureFlagConfigEtag';
const FEATURE_FLAG_RESULTS = 'wmFeatureFlagResults';
const CONFIG_CACHE_START = 'wmConfigCacheStart';
const CACHE_USED = 'Cache was used due to remote loading failing.';
const FALLBACK_USED_REMOTE_LOAD_FAILED = 'Fallback used because remote config loading failed.';
const FALLBACK_OR_CACHE_USED_REMOTE_LOAD_FAILED = 'Fallback or cache used because remote config loading failed.';
const FALLBACK_USED_FLAG_NOT_IN_REMOTE = 'Fallback used because a requested flag was present in it and not the remote.';
const REMOTE_CONFIG_USED_NO_FALLBACK_PRESENT = 'Remote config used because a fallback config is not present.';
const FAILED_TO_READ_CONFIG_FROM_STORAGE = 'There was a problem reading the config object from storage, possibly due to invalid config JSON.';
const UNABLE_TO_ACCESS_LOCAL_STORAGE = 'Unable to access localStorage';
const UNABLE_TO_ACCESS_SESSION_STORAGE = 'Unable to access sessionStorage';
const MULTIPLE_INSTANCES_SUPPORT = 'A clientId must be provided for multiple instance support.';

/**
 * @module wm-feature-flag-client
 */
class FeatureFlagStorage {
  constructor(storageType, clientId) {
    this.storageType = void 0;
    this.clientId = void 0;
    this.storage = void 0;
    this.warnings = void 0;
    this.storageType = storageType;
    this.clientId = clientId;
    this.warnings = [];

    if (!this.clientId) {
      const warning = {
        code: 'MULTIPLE_INSTANCES_SUPPORT',
        message: `${MULTIPLE_INSTANCES_SUPPORT}`
      };
      this.warnings.push(warning);
    }

    switch (this.storageType) {
      case 'localStorage':
        try {
          this.storage = localStorage;
        } catch (error) {
          const warning = {
            code: 'UNABLE_TO_ACCESS_LOCAL_STORAGE',
            message: `${UNABLE_TO_ACCESS_LOCAL_STORAGE}: ${error}`
          };
          this.warnings.push(warning);
        }

        break;

      case 'sessionStorage':
        try {
          this.storage = sessionStorage;
        } catch (error) {
          const warning = {
            code: 'UNABLE_TO_ACCESS_SESSION_STORAGE',
            message: `${UNABLE_TO_ACCESS_SESSION_STORAGE}: ${error}`
          };
          this.warnings.push(warning);
        }

        break;

      case 'inMemory': // todo: implement an in-memory storage option for non-browser env

      default:
        try {
          this.storage = localStorage;
          break;
        } catch (error) {
          const warning = {
            code: 'UNABLE_TO_ACCESS_LOCAL_STORAGE',
            message: `${UNABLE_TO_ACCESS_LOCAL_STORAGE}: ${error}`
          };
          this.warnings.push(warning);
        }

    }
  }

  get(key) {
    let storeItem;
    if (typeof Storage === 'undefined') return;
    let storageKey = key;
    if (this.clientId) storageKey = `${storageKey}-${this.clientId}`;

    try {
      storeItem = this.storage.getItem(storageKey);
    } catch (error) {
      const warning = {
        code: 'UNABLE_TO_ACCESS_LOCAL_STORAGE',
        message: `${UNABLE_TO_ACCESS_LOCAL_STORAGE}: ${error}`
      };
      this.warnings.push(warning);
    }

    return storeItem;
  }

  set(key, value) {
    if (typeof Storage === 'undefined') return;
    let storageKey = key;
    if (this.clientId) storageKey = `${storageKey}-${this.clientId}`;

    try {
      this.storage.setItem(storageKey, value);
    } catch (error) {
      const warning = {
        code: 'UNABLE_TO_ACCESS_LOCAL_STORAGE',
        message: `${UNABLE_TO_ACCESS_LOCAL_STORAGE}: ${error}`
      };
      this.warnings.push(warning);
    }

    return;
  }

  delete(key) {
    if (typeof Storage === 'undefined') return;
    let storageKey = key;
    if (this.clientId) storageKey = `${storageKey}-${this.clientId}`;

    try {
      this.storage.deleteItem(storageKey);
    } catch (error) {
      const warning = {
        code: 'UNABLE_TO_ACCESS_LOCAL_STORAGE',
        message: `${UNABLE_TO_ACCESS_LOCAL_STORAGE}: ${error}`
      };
      this.warnings.push(warning);
    }

    return;
  }

}

export { APP_USER_ID as A, CONFIG_CACHE_START as C, FeatureFlagStorage as F, REMOTE_CONFIG_USED_NO_FALLBACK_PRESENT as R, FEATURE_FLAG_CONFIG as a, FEATURE_FLAG_CONFIG_ETAG as b, config as c, FEATURE_FLAG_USER_ID as d, CACHE_USED as e, FALLBACK_USED_REMOTE_LOAD_FAILED as f, FALLBACK_OR_CACHE_USED_REMOTE_LOAD_FAILED as g, FEATURE_FLAG_RESULTS as h, FALLBACK_USED_FLAG_NOT_IN_REMOTE as i, FAILED_TO_READ_CONFIG_FROM_STORAGE as j };
