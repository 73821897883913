import { generateUUID, makeRequest } from '@turnercode/cdp-utils-js';
import { c as config, F as FeatureFlagStorage, a as FEATURE_FLAG_CONFIG, b as FEATURE_FLAG_CONFIG_ETAG, R as REMOTE_CONFIG_USED_NO_FALLBACK_PRESENT, A as APP_USER_ID, d as FEATURE_FLAG_USER_ID, C as CONFIG_CACHE_START, e as CACHE_USED, f as FALLBACK_USED_REMOTE_LOAD_FAILED, g as FALLBACK_OR_CACHE_USED_REMOTE_LOAD_FAILED, h as FEATURE_FLAG_RESULTS, i as FALLBACK_USED_FLAG_NOT_IN_REMOTE, j as FAILED_TO_READ_CONFIG_FROM_STORAGE } from './featureFlagStorage-5712976a.esm.js';
import 'core-js/modules/es.array.iterator';

const setPolyfills = () => {
  /* Object.entries polyfill */
  if (!Object.entries) {
    Object.entries = function (obj) {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = new Array(i); // preallocate the Array

      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
  }
  /* Array.prototype.find polyfill */


  if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
      value: function (predicate) {
        // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw TypeError('"this" is null or not defined');
        }

        const o = Object(this); // 2. Let len be ? ToLength(? Get(O, "length")).

        const len = o.length >>> 0; // 3. If IsCallable(predicate) is false, throw a TypeError exception.

        if (typeof predicate !== 'function') {
          throw TypeError('predicate must be a function');
        } // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        // eslint-disable-next-line prefer-rest-params


        const thisArg = arguments[1]; // 5. Let k be 0.

        let k = 0; // 6. Repeat, while k < len

        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          const kValue = o[k];

          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          } // e. Increase k by 1.


          k++;
        } // 7. Return undefined.


        return undefined;
      },
      configurable: true,
      writable: true
    });
  }
};

const ERROR = 'input is invalid type';
const HEX_CHARS = '0123456789abcdef'.split('');
const EXTRA = [-2147483648, 8388608, 32768, 128];
const SHIFT = [24, 16, 8, 0];
const K = [0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5, 0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174, 0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da, 0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967, 0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85, 0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070, 0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3, 0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2];
class Sha256 {
  constructor() {
    this.blocks = [];
    this.h0 = void 0;
    this.h1 = void 0;
    this.h2 = void 0;
    this.h3 = void 0;
    this.h4 = void 0;
    this.h5 = void 0;
    this.h6 = void 0;
    this.h7 = void 0;
    this.block = void 0;
    this.start = void 0;
    this.bytes = void 0;
    this.hBytes = void 0;
    this.finalized = void 0;
    this.hashed = void 0;
    this.first = void 0;
    this.lastByteIndex = void 0;
    this.blocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // 256

    this.h0 = 0x6a09e667;
    this.h1 = 0xbb67ae85;
    this.h2 = 0x3c6ef372;
    this.h3 = 0xa54ff53a;
    this.h4 = 0x510e527f;
    this.h5 = 0x9b05688c;
    this.h6 = 0x1f83d9ab;
    this.h7 = 0x5be0cd19;
    this.block = this.start = this.bytes = this.hBytes = 0;
    this.finalized = this.hashed = false;
    this.first = true;
  }

  create() {
    return this;
  }

  update(message) {
    if (this.finalized) {
      return;
    }

    let notString;
    const type = typeof message;

    if (type !== 'string') {
      if (type === 'object') {
        if (message === null) {
          throw new Error(ERROR);
        } else if (message.constructor === ArrayBuffer) {
          message = new Uint8Array(message);
        } else if (!Array.isArray(message)) {
          if (!ArrayBuffer.isView(message)) {
            throw new Error(ERROR);
          }
        }
      } else {
        throw new Error(ERROR);
      }

      notString = true;
    }

    let code,
        index = 0,
        i;
    const length = message.length,
          blocks = this.blocks;

    while (index < length) {
      if (this.hashed) {
        this.hashed = false;
        blocks[0] = this.block;
        blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      }

      if (notString) {
        for (i = this.start; index < length && i < 64; ++index) {
          blocks[i >> 2] |= message[index] << SHIFT[i++ & 3];
        }
      } else {
        for (i = this.start; index < length && i < 64; ++index) {
          code = message.charCodeAt(index);

          if (code < 0x80) {
            blocks[i >> 2] |= code << SHIFT[i++ & 3];
          } else if (code < 0x800) {
            blocks[i >> 2] |= (0xc0 | code >> 6) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          } else if (code < 0xd800 || code >= 0xe000) {
            blocks[i >> 2] |= (0xe0 | code >> 12) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 6 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          } else {
            code = 0x10000 + ((code & 0x3ff) << 10 | message.charCodeAt(++index) & 0x3ff);
            blocks[i >> 2] |= (0xf0 | code >> 18) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 12 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 6 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          }
        }
      }

      this.lastByteIndex = i;
      this.bytes += i - this.start;

      if (i >= 64) {
        this.block = blocks[16];
        this.start = i - 64;
        this.hash();
        this.hashed = true;
      } else {
        this.start = i;
      }
    }

    if (this.bytes > 4294967295) {
      this.hBytes += this.bytes / 4294967296 << 0;
      this.bytes = this.bytes % 4294967296;
    }

    return this;
  }

  finalize() {
    if (this.finalized) {
      return;
    }

    this.finalized = true;
    const blocks = this.blocks,
          i = this.lastByteIndex;
    blocks[16] = this.block;
    blocks[i >> 2] |= EXTRA[i & 3];
    this.block = blocks[16];

    if (i >= 56) {
      if (!this.hashed) {
        this.hash();
      }

      blocks[0] = this.block;
      blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
    }

    blocks[14] = this.hBytes << 3 | this.bytes >>> 29;
    blocks[15] = this.bytes << 3;
    this.hash();
  }

  hash() {
    let a = this.h0,
        b = this.h1,
        c = this.h2,
        d = this.h3,
        e = this.h4,
        f = this.h5,
        g = this.h6,
        h = this.h7,
        j,
        s0,
        s1,
        maj,
        t1,
        t2,
        ch,
        ab,
        da,
        cd,
        bc;
    const blocks = this.blocks;

    for (j = 16; j < 64; ++j) {
      // rightrotate
      t1 = blocks[j - 15];
      s0 = (t1 >>> 7 | t1 << 25) ^ (t1 >>> 18 | t1 << 14) ^ t1 >>> 3;
      t1 = blocks[j - 2];
      s1 = (t1 >>> 17 | t1 << 15) ^ (t1 >>> 19 | t1 << 13) ^ t1 >>> 10;
      blocks[j] = blocks[j - 16] + s0 + blocks[j - 7] + s1 << 0;
    }

    bc = b & c;

    for (j = 0; j < 64; j += 4) {
      if (this.first) {
        ab = 704751109;
        t1 = blocks[0] - 210244248;
        h = t1 - 1521486534 << 0;
        d = t1 + 143694565 << 0;
        this.first = false;
      } else {
        s0 = (a >>> 2 | a << 30) ^ (a >>> 13 | a << 19) ^ (a >>> 22 | a << 10);
        s1 = (e >>> 6 | e << 26) ^ (e >>> 11 | e << 21) ^ (e >>> 25 | e << 7);
        ab = a & b;
        maj = ab ^ a & c ^ bc;
        ch = e & f ^ ~e & g;
        t1 = h + s1 + ch + K[j] + blocks[j];
        t2 = s0 + maj;
        h = d + t1 << 0;
        d = t1 + t2 << 0;
      }

      s0 = (d >>> 2 | d << 30) ^ (d >>> 13 | d << 19) ^ (d >>> 22 | d << 10);
      s1 = (h >>> 6 | h << 26) ^ (h >>> 11 | h << 21) ^ (h >>> 25 | h << 7);
      da = d & a;
      maj = da ^ d & b ^ ab;
      ch = h & e ^ ~h & f;
      t1 = g + s1 + ch + K[j + 1] + blocks[j + 1];
      t2 = s0 + maj;
      g = c + t1 << 0;
      c = t1 + t2 << 0;
      s0 = (c >>> 2 | c << 30) ^ (c >>> 13 | c << 19) ^ (c >>> 22 | c << 10);
      s1 = (g >>> 6 | g << 26) ^ (g >>> 11 | g << 21) ^ (g >>> 25 | g << 7);
      cd = c & d;
      maj = cd ^ c & a ^ da;
      ch = g & h ^ ~g & e;
      t1 = f + s1 + ch + K[j + 2] + blocks[j + 2];
      t2 = s0 + maj;
      f = b + t1 << 0;
      b = t1 + t2 << 0;
      s0 = (b >>> 2 | b << 30) ^ (b >>> 13 | b << 19) ^ (b >>> 22 | b << 10);
      s1 = (f >>> 6 | f << 26) ^ (f >>> 11 | f << 21) ^ (f >>> 25 | f << 7);
      bc = b & c;
      maj = bc ^ b & d ^ cd;
      ch = f & g ^ ~f & h;
      t1 = e + s1 + ch + K[j + 3] + blocks[j + 3];
      t2 = s0 + maj;
      e = a + t1 << 0;
      a = t1 + t2 << 0;
    }

    this.h0 = this.h0 + a << 0;
    this.h1 = this.h1 + b << 0;
    this.h2 = this.h2 + c << 0;
    this.h3 = this.h3 + d << 0;
    this.h4 = this.h4 + e << 0;
    this.h5 = this.h5 + f << 0;
    this.h6 = this.h6 + g << 0;
    this.h7 = this.h7 + h << 0;
  }

  hex() {
    this.finalize();
    const h0 = this.h0,
          h1 = this.h1,
          h2 = this.h2,
          h3 = this.h3,
          h4 = this.h4,
          h5 = this.h5,
          h6 = this.h6,
          h7 = this.h7;
    let hex = HEX_CHARS[h0 >> 28 & 0x0f] + HEX_CHARS[h0 >> 24 & 0x0f] + HEX_CHARS[h0 >> 20 & 0x0f] + HEX_CHARS[h0 >> 16 & 0x0f] + HEX_CHARS[h0 >> 12 & 0x0f] + HEX_CHARS[h0 >> 8 & 0x0f] + HEX_CHARS[h0 >> 4 & 0x0f] + HEX_CHARS[h0 & 0x0f] + HEX_CHARS[h1 >> 28 & 0x0f] + HEX_CHARS[h1 >> 24 & 0x0f] + HEX_CHARS[h1 >> 20 & 0x0f] + HEX_CHARS[h1 >> 16 & 0x0f] + HEX_CHARS[h1 >> 12 & 0x0f] + HEX_CHARS[h1 >> 8 & 0x0f] + HEX_CHARS[h1 >> 4 & 0x0f] + HEX_CHARS[h1 & 0x0f] + HEX_CHARS[h2 >> 28 & 0x0f] + HEX_CHARS[h2 >> 24 & 0x0f] + HEX_CHARS[h2 >> 20 & 0x0f] + HEX_CHARS[h2 >> 16 & 0x0f] + HEX_CHARS[h2 >> 12 & 0x0f] + HEX_CHARS[h2 >> 8 & 0x0f] + HEX_CHARS[h2 >> 4 & 0x0f] + HEX_CHARS[h2 & 0x0f] + HEX_CHARS[h3 >> 28 & 0x0f] + HEX_CHARS[h3 >> 24 & 0x0f] + HEX_CHARS[h3 >> 20 & 0x0f] + HEX_CHARS[h3 >> 16 & 0x0f] + HEX_CHARS[h3 >> 12 & 0x0f] + HEX_CHARS[h3 >> 8 & 0x0f] + HEX_CHARS[h3 >> 4 & 0x0f] + HEX_CHARS[h3 & 0x0f] + HEX_CHARS[h4 >> 28 & 0x0f] + HEX_CHARS[h4 >> 24 & 0x0f] + HEX_CHARS[h4 >> 20 & 0x0f] + HEX_CHARS[h4 >> 16 & 0x0f] + HEX_CHARS[h4 >> 12 & 0x0f] + HEX_CHARS[h4 >> 8 & 0x0f] + HEX_CHARS[h4 >> 4 & 0x0f] + HEX_CHARS[h4 & 0x0f] + HEX_CHARS[h5 >> 28 & 0x0f] + HEX_CHARS[h5 >> 24 & 0x0f] + HEX_CHARS[h5 >> 20 & 0x0f] + HEX_CHARS[h5 >> 16 & 0x0f] + HEX_CHARS[h5 >> 12 & 0x0f] + HEX_CHARS[h5 >> 8 & 0x0f] + HEX_CHARS[h5 >> 4 & 0x0f] + HEX_CHARS[h5 & 0x0f] + HEX_CHARS[h6 >> 28 & 0x0f] + HEX_CHARS[h6 >> 24 & 0x0f] + HEX_CHARS[h6 >> 20 & 0x0f] + HEX_CHARS[h6 >> 16 & 0x0f] + HEX_CHARS[h6 >> 12 & 0x0f] + HEX_CHARS[h6 >> 8 & 0x0f] + HEX_CHARS[h6 >> 4 & 0x0f] + HEX_CHARS[h6 & 0x0f];
    hex += HEX_CHARS[h7 >> 28 & 0x0f] + HEX_CHARS[h7 >> 24 & 0x0f] + HEX_CHARS[h7 >> 20 & 0x0f] + HEX_CHARS[h7 >> 16 & 0x0f] + HEX_CHARS[h7 >> 12 & 0x0f] + HEX_CHARS[h7 >> 8 & 0x0f] + HEX_CHARS[h7 >> 4 & 0x0f] + HEX_CHARS[h7 & 0x0f];
    return hex;
  }

  toString() {
    return this.hex();
  }

  digest() {
    this.finalize();
    const h0 = this.h0,
          h1 = this.h1,
          h2 = this.h2,
          h3 = this.h3,
          h4 = this.h4,
          h5 = this.h5,
          h6 = this.h6,
          h7 = this.h7;
    const arr = [h0 >> 24 & 0xff, h0 >> 16 & 0xff, h0 >> 8 & 0xff, h0 & 0xff, h1 >> 24 & 0xff, h1 >> 16 & 0xff, h1 >> 8 & 0xff, h1 & 0xff, h2 >> 24 & 0xff, h2 >> 16 & 0xff, h2 >> 8 & 0xff, h2 & 0xff, h3 >> 24 & 0xff, h3 >> 16 & 0xff, h3 >> 8 & 0xff, h3 & 0xff, h4 >> 24 & 0xff, h4 >> 16 & 0xff, h4 >> 8 & 0xff, h4 & 0xff, h5 >> 24 & 0xff, h5 >> 16 & 0xff, h5 >> 8 & 0xff, h5 & 0xff, h6 >> 24 & 0xff, h6 >> 16 & 0xff, h6 >> 8 & 0xff, h6 & 0xff];
    arr.push(h7 >> 24 & 0xff, h7 >> 16 & 0xff, h7 >> 8 & 0xff, h7 & 0xff);
    return arr;
  }

  array() {
    return this.digest();
  }

  arrayBuffer() {
    this.finalize();
    const buffer = new ArrayBuffer(32);
    const dataView = new DataView(buffer);
    dataView.setUint32(0, this.h0);
    dataView.setUint32(4, this.h1);
    dataView.setUint32(8, this.h2);
    dataView.setUint32(12, this.h3);
    dataView.setUint32(16, this.h4);
    dataView.setUint32(20, this.h5);
    dataView.setUint32(24, this.h6);
    dataView.setUint32(28, this.h7);
    return buffer;
  }

}

/**
 * @module wm-feature-flag-client
 */
/* set polyfills for IE11 support */

setPolyfills();
/**
 * Provides core feature flag querying functionality for
 * determining which features are enabled for a specific user.
 * @implements {IFlagFlagClient}
 */

class FeatureFlagClient {
  /**
   * Creates a new instance of the [FeatureFlagClient] class.
   * @param context The conext object containing the userId and config data
   * @param config Optional. The feature flag config file.
   *
   * @remarks
   * The config file at location context.configUrl will be fetched from AWS S3 unless a config file is provided
   */
  constructor(context, fallbackConfig, quickInit = false) {
    this.config = void 0;
    this.configRefreshIntervalDefault = void 0;
    this.context = void 0;
    this.fallbackConfig = void 0;
    this.featureFlagUserId = void 0;
    this.initialized = void 0;
    this.libraryLanguage = void 0;
    this.storage = void 0;
    this.quickInit = void 0;
    this.globalWarnings = void 0;

    this.addStorageWarnings = () => {
      const addStorageWarningsWarnings = [];

      if (this.storage.warnings && this.storage.warnings.length > 0) {
        this.storage.warnings.map(warning => {
          addStorageWarningsWarnings.push(warning);
        });
      }

      return addStorageWarningsWarnings;
    };

    this.targetingPropsToLowerCase = targetingProperties => {
      return Object.entries(targetingProperties).reduce((acc, [key, value]) => {
        let valueLowerCase;
        if (typeof value === 'string') valueLowerCase = value.toLowerCase();

        if (Array.isArray(value)) {
          valueLowerCase = value.map(entry => entry.toLowerCase());
        }

        acc[key.toLowerCase()] = valueLowerCase;
        return acc;
      }, {});
    };

    if (!context) throw new Error('Please provide a context object to the constructor.');
    if (!context.configUrl && !fallbackConfig) throw new Error('Please provide either a config url or a valid config object.');
    this.config = undefined;
    this.configRefreshIntervalDefault = config.minimumPollFrequencySeconds;
    this.context = context;
    this.fallbackConfig = fallbackConfig;
    this.featureFlagUserId = '';
    this.libraryLanguage = config.ffLibraryLanguage;
    this.initialized = false;
    this.quickInit = quickInit;
    const storageType = context && context.storageType ? context.storageType : '';
    this.storage = new FeatureFlagStorage(storageType, context.clientId);
    this.globalWarnings = [];
  }
  /**
   * Creates a hash of the userId and saltKey (using browser'crypto / msCrytpo' api)
   * @param userId The userId used to create the hash
   * @param salt The salt used to create the hash
   * @return {string} hash
   */


  createHash(userId, salt) {
    const sha256 = new Sha256();
    const hash = sha256.create();
    hash.update(`${userId}${salt}`);
    const hashValue = hash.hex();
    return hashValue;
  }
  /**
   * Creates a user id
   * @return {string} userId
   */


  createUserId() {
    const userId = generateUUID();
    return userId;
  }
  /**
   * Creates a 2-digit string from the hash to be used as an index for
   * comparing against the rollout percentage of the feature to determine
   * whether the feature is enabled or disabled
   * @param hash
   * @return {string} 2-digit segment of the hash
   */


  getUserFeatureIndex(hash) {
    const hashSegment = parseInt(hash.substring(0, 10), 16);
    return hashSegment.toString().slice(-2);
  }
  /**
   * Initialization code to ensure that the userId, config and ffLibraryLanguage are set
   * @return {void}
   */


  async init() {
    const initWarnings = [];
    const {
      config,
      fallbackConfig,
      initialized,
      featureFlagUserId,
      libraryLanguage,
      storage,
      configRefreshIntervalDefault,
      quickInit
    } = this;
    const {
      configUrl,
      userId,
      configRefreshInterval
    } = this.context; // get user-defined refresh interval or use default if none provided

    const cfgRefreshInterval = typeof configRefreshInterval !== 'undefined' ? configRefreshInterval : configRefreshIntervalDefault;
    const cacheIsExpired = this.checkConfigCacheExpiry(cfgRefreshInterval); // initialization steps:
    // 1. ensure we have feature flag configuration. first look for config in storage,
    //    otherwise fetch it from configUrl location

    if (!config || cacheIsExpired) {
      const configFromStorage = storage.get(FEATURE_FLAG_CONFIG);

      try {
        if (configFromStorage) this.config = JSON.parse(configFromStorage);
      } catch (error) {
        const warning = {
          code: 'FAILED_TO_READ_CONFIG_FROM_STORAGE',
          message: FAILED_TO_READ_CONFIG_FROM_STORAGE
        };
        initWarnings.push(warning);
      }

      if (!configFromStorage || cacheIsExpired) {
        // check if config file has changed before downloading
        // by populating 'If-None-Match' header with value of the previous etag
        // get config file (if it has been updated), save it in storage along with new eTag
        if (configUrl) {
          // set fallback config as default in case remote fetch fails
          if (!cacheIsExpired && fallbackConfig) this.updateConfig(fallbackConfig);
          let prevETag = storage.get(FEATURE_FLAG_CONFIG_ETAG) || '-1'; // if no config in storage, fetch file

          prevETag = !configFromStorage ? '-1' : prevETag;

          try {
            if (quickInit && fallbackConfig) {
              if (!configFromStorage) this.updateConfig(fallbackConfig);
              this.loadAndSetRemoteConfig(configUrl, prevETag);
              return initWarnings;
            }

            if (quickInit && !fallbackConfig) {
              const warning = {
                code: 'REMOTE_CONFIG_USED_NO_FALLBACK_PRESENT',
                message: REMOTE_CONFIG_USED_NO_FALLBACK_PRESENT
              };
              initWarnings.push(warning);
            }

            await this.loadAndSetRemoteConfig(configUrl, prevETag);
          } catch (error) {
            // log and continue to use cached file version, or set fallbackConfig if available
            if (configFromStorage || fallbackConfig) {
              this.setNoNewConfigWarning();
            } else {
              throw new Error(`Failed to load config file - no config url or default config provided. ${error}`);
            }
          }
        } else {
          // use fallbackConfig if there is no configUrl
          if (!fallbackConfig) throw new Error('Failed to load config file - no config url or default config provided.');
          this.updateConfig(fallbackConfig);
        }
      }
    } // pass through if instance already initialized


    if (initialized) return initWarnings; // 2. ensure we have a userId. if it's not in the context object, check storage.

    if (!userId) {
      const _userId = storage.get(APP_USER_ID);

      if (_userId) this.context.userId = _userId;
    } else {
      storage.set(APP_USER_ID, userId);
    } // 3. ensure we have a featureFlagUserId. if there isn't one in storage, create one.


    if (!featureFlagUserId) {
      const ffUserId = storage.get(FEATURE_FLAG_USER_ID);
      if (ffUserId) this.featureFlagUserId = ffUserId;

      if (!ffUserId) {
        this.featureFlagUserId = this.createUserId();
        storage.set(FEATURE_FLAG_USER_ID, this.featureFlagUserId);
      }
    } // 4. ensure that the ffLibraryLanguage property is set


    if (!this.context.userTargetingProperties || this.context.userTargetingProperties && !this.context.userTargetingProperties.ffLibraryLanguage) {
      const userTargetingProperties = {
        ffLibraryLanguage: libraryLanguage
      };
      this.updateContext(undefined, userTargetingProperties);
    }

    this.initialized = true;
    return initWarnings;
  }
  /**
   * Determine whether the flag config has been cached longer than the desired interval
   * @param interval The maximum time (in ms) to cache the config file before fetching a new copy
   * @return {boolean}
   */


  checkConfigCacheExpiry(interval) {
    const {
      storage
    } = this;
    const now = new Date();
    let expired = false;

    const setIntervalStart = timestamp => storage.set(CONFIG_CACHE_START, timestamp); // check for a timestamp in browser storage


    const configCacheStartSerialized = storage.get(CONFIG_CACHE_START); // if there's no timestamp in browser storage, create one

    if (!configCacheStartSerialized) {
      const _now = new Date();

      setIntervalStart(JSON.stringify(_now.getTime()));
    }

    const configCacheStart = configCacheStartSerialized ? JSON.parse(configCacheStartSerialized) : now.getTime();
    const cacheTimeElapsed = now.getTime() - configCacheStart; // in ms

    expired = cacheTimeElapsed > interval ? true : false;

    if (expired === true) {
      const _now2 = new Date();

      setIntervalStart(JSON.stringify(_now2.getTime()));
    }

    return expired;
  }

  async isFeatureEnabled(flagName) {
    return (await this.queryFeatureFlag(flagName)).enabled;
  }
  /**
   * When remote fetch of config file fails (or is unchanged - 304)
   * set warning and appropriate backup config
   * @param interval The maximum time (in ms) to cache the config file before fetching a new copy
   * @return {void}
   */


  setNoNewConfigWarning() {
    const {
      fallbackConfig,
      storage
    } = this;
    const configFromStorage = storage.get(FEATURE_FLAG_CONFIG); // if there is no new config file version available, handle the 304 error
    // log and continue to use cached file version, or set fallbackConfig if available

    if (configFromStorage) {
      const warning = {
        code: 'CACHE_USED',
        message: CACHE_USED
      };
      this.globalWarnings.push(warning);
    } else if (!configFromStorage && fallbackConfig) {
      this.globalWarnings.push({
        code: 'FALLBACK_USED_REMOTE_LOAD_FAILED',
        message: CACHE_USED
      });
      this.updateConfig(fallbackConfig);
    }
  } // Taken directly from the FF Client repo codebase


  parseHeaders(responseHeaders) {
    const responseHeadersArray = responseHeaders.split('\r\n');
    return responseHeadersArray.reduce(function (acc, current) {
      const parts = current.split(': '); // @ts-ignore

      acc[parts[0]] = parts[1];
      return acc;
    }, {});
  }

  async loadAndSetRemoteConfig(configUrl, prevETag) {
    try {
      const response = await this.loadConfig(configUrl, prevETag);

      if (response) {
        this.config = response.data ? response.data : this.config;

        if (!response.data) {
          this.globalWarnings.push({
            code: 'FALLBACK_USED_REMOTE_LOAD_FAILED',
            message: FALLBACK_USED_REMOTE_LOAD_FAILED
          });
        }

        let eTag = response.headers && response.headers.etag ? response.headers.etag : '';

        if (response.headers && typeof response.headers === 'string') {
          const responseHeaders = this.parseHeaders(response.headers);
          eTag = responseHeaders.etag;
        }

        if (eTag) this.storage.set(FEATURE_FLAG_CONFIG_ETAG, eTag); // store config to temp storage

        this.storage.set(FEATURE_FLAG_CONFIG, JSON.stringify(this.config));
      }
    } catch (err) {
      // remote fetch unsuccessfull, go to cache (or fallback)
      this.globalWarnings.push({
        code: 'FALLBACK_OR_CACHE_USED_REMOTE_LOAD_FAILED',
        message: FALLBACK_OR_CACHE_USED_REMOTE_LOAD_FAILED
      });
      this.setNoNewConfigWarning();
    }
  }
  /**
   * Loads the config file from the configUrl location
   * @param configUrl Uri for the desired feature flag config file
   * @param eTag The value of the etag header from the previous request, default to -1
   */


  async loadConfig(configUrl, eTag = '-1') {
    let response;
    const url = `${configUrl}?version=${config.ffLibraryVersionCode}`;
    const headers = {
      'If-None-Match': eTag
    };

    try {
      response = await makeRequest({
        url,
        method: 'GET',
        headers
      });
    } catch (error) {
      throw new Error(`Failed to fetch config file: ${error}`);
    }

    return response;
  }
  /**
   * Update the config object
   * @param config A feature flag configuration file
   */


  updateConfig(config) {
    this.config = config;
    this.storage.set(FEATURE_FLAG_CONFIG, JSON.stringify(config));
  }
  /**
   * Update the context object
   * @param userId a string id
   * @param userTargeting an object conatining user cohort targeting criteria
   * @param replaceAllTargeting a boolean the determine where or not to replace all targeting
   */


  updateContext(userId, userTargeting, replaceAllTargeting) {
    const newContext = { ...this.context
    };
    if (userId) newContext.userId = userId;

    if (replaceAllTargeting) {
      newContext.userTargetingProperties = userTargeting;
    } else {
      const mergedTargetingProperties = { ...newContext.userTargetingProperties,
        ...userTargeting
      };
      newContext.userTargetingProperties = mergedTargetingProperties;
    }

    this.context = newContext;
  }
  /**
   * Add Storage warnings to FeatureFlagClient.warnings array
   * @param warnings An array of IWarning objects
   */


  /**
   * Determine which cohort configuration from the feature config file to use
   * @param context The conext object containing the userId and config data
   * @param cohortConfigs cohort configuration objects from the feature config file
   * @return {ICohortConfig}
   */
  getCohortConfig(context, cohortConfigs) {
    // set a defaut cohort config in case no matches found
    const defaultIdType = context.userId ? 'appUserId' : 'ffUserId';
    let cohortConfig = {
      rolloutValue: '-1',
      cohortPriority: 1,
      stickinessProperty: defaultIdType
    }; // sort by 'cohortPriority' property

    const cohortConfigsSorted = [...cohortConfigs];
    cohortConfigsSorted.sort((a, b) => a.cohortPriority > b.cohortPriority ? 1 : -1); // transform context keys to lower case for case insensitive matching

    const clientTargetingProperties = this.targetingPropsToLowerCase(context.userTargetingProperties); // identify cohort config to use by validating all 'cohortCriteria' fields

    const matchedCohortConfig = cohortConfigsSorted.find(cohortConfig => {
      let matchDetected = false; // if there is no userId provider and the stickinessProperty specifies userId - do not match

      if (!context.userId && cohortConfig.stickinessProperty === 'appUserId') return false; // if no cohort criteria is present, consider the cohort a match

      if (!cohortConfig.cohortCriteria || cohortConfig.cohortCriteria.length < 1) return true;
      const cohortFields = cohortConfig.cohortCriteria; // iterate through cohort criteria

      for (let i = 0; i < cohortFields.length; i++) {
        let {
          requiredFieldName
        } = cohortFields[i];
        const {
          requiredFieldValues
        } = cohortFields[i];
        requiredFieldName = requiredFieldName.toLowerCase(); // if at least one cohort field value is present in context object, consider the filed validated

        const cohortFieldFoundInContext = requiredFieldValues.some(fieldValue => {
          fieldValue = fieldValue.toLowerCase(); // match if fieldValue is empty string and property is absent in clientTargetingProperties
          // eslint-disable-next-line no-prototype-builtins

          if (!clientTargetingProperties.hasOwnProperty(requiredFieldName) && fieldValue === '') return true; // check for both string and array values of the matching property

          if (typeof clientTargetingProperties[requiredFieldName] === 'string') return clientTargetingProperties[requiredFieldName] === fieldValue;

          if (Array.isArray(clientTargetingProperties[requiredFieldName])) {
            return clientTargetingProperties[requiredFieldName].some(contextValue => contextValue === fieldValue);
          }
        });
        matchDetected = cohortFieldFoundInContext;
        if (!matchDetected) break;
      }

      return matchDetected;
    }); // overwrite default cohort config if a matching config was found

    if (matchedCohortConfig) cohortConfig = { ...matchedCohortConfig
    };
    return cohortConfig;
  }
  /**
   * set feature flag results into storage
   * @param results Either a single result (object) or an array of results
   * @return {IQueryFeatureResult[]}
   */


  cacheFlagResults(results) {
    const {
      storage
    } = this;
    let prevResults = [],
        prevResultsReduced = [],
        newResults = []; // populate newResults array from results argument

    if (Array.isArray(results)) {
      newResults = results ? [...results] : [];
    } else {
      newResults.push(results);
    } // get cached flag values from storage and deserialize


    const storedResultsString = storage.get(FEATURE_FLAG_RESULTS);
    const storedResults = storedResultsString ? JSON.parse(storedResultsString) : [];
    prevResults = [...storedResults]; // filter out duplicates from prevResults

    if (prevResults.length > 0) {
      prevResultsReduced = prevResults.reduce((accumulator, currentValue) => {
        if (newResults.find(result => result.flagId === currentValue.flagId)) {
          return accumulator;
        } else {
          return [...accumulator, currentValue];
        }
      }, []);
    } // merge previous and new results and set into storage


    const mergedResults = [...prevResultsReduced, ...newResults];
    storage.set(FEATURE_FLAG_RESULTS, JSON.stringify(mergedResults));
    return mergedResults;
  }
  /**
   * check if the flag's enabled value has changed compared with the cached value
   * @param result The processed new flag to compare
   * @return {boolean}
   */


  checkIfFlagUpdated(result) {
    const {
      storage
    } = this; // get cached flag values from storage and deserialize

    const storedResultsString = storage.get(FEATURE_FLAG_RESULTS);
    const storedResults = storedResultsString ? JSON.parse(storedResultsString) : []; // if no results in storage, return true (ie, flag updated)

    if (storedResults.length < 1) return true; // compare values of stored flag and fetched flag

    const prevFlag = storedResults.find(prevFlag => prevFlag.flagId === result.flagId);
    if (!prevFlag) return true;
    const hasFlagChanged = prevFlag.enabled !== result.enabled;
    return hasFlagChanged;
  }
  /**
   * Checks whether or not a given feature is enabled for a specific user
   * @param flagId
   * @param isQueryAll is this part of a queryAllFeatureFlags call?
   * @return {IQueryFeatureResult} response object containing feature name, 'enabled' boolean and userId
   */


  async queryFeatureFlag(flagId, isQueryAll = false) {
    const {
      context,
      fallbackConfig
    } = this;
    let enabled = false,
        flagConfig,
        flagName,
        hashId,
        operationalId,
        userFeatureIndex,
        userIdType = 'appUserId',
        warnings = [];

    const mergeWarnings = arrayToMerge => {
      const globalWarnings = isQueryAll ? [] : this.globalWarnings;
      return warnings.concat(arrayToMerge, globalWarnings);
    };

    const getFlagConfig = (config, flagId) => {
      return config.flags.find(flag => flag.id === flagId);
    };

    const getFallbackConfig = (fallbackConfig, flagId) => {
      flagConfig = getFlagConfig(fallbackConfig, flagId);

      if (flagConfig) {
        const addStorageWarningsWarnings = this.addStorageWarnings();
        warnings = mergeWarnings(addStorageWarningsWarnings);
        const result = {
          clientId: context.clientId,
          flagId: flagConfig.id,
          flagName: flagConfig.name ? flagConfig.name : undefined,
          enabled: flagConfig.defaultValue,
          updatedSinceLastQuery: true,
          warnings: warnings
        };
        result.updatedSinceLastQuery = this.checkIfFlagUpdated(result);
        this.cacheFlagResults(result);
        return result;
      }
    };

    try {
      const initWwarnings = await this.init();
      warnings = initWwarnings.concat(warnings);
      operationalId = this.context.userId ? this.context.userId : undefined;
      if (!this.config || !this.config.flags) throw new Error('Operation failed - no config file or invalid config file detected.'); // get the feature flag configuration matching the feature name provided

      flagConfig = getFlagConfig(this.config, flagId); // if the flag is not found, try again using the fallbackConfig, if available

      if (!flagConfig) {
        if (fallbackConfig) {
          const response = getFallbackConfig(fallbackConfig, flagId);

          if (response) {
            const warning = {
              code: 'FALLBACK_USED_FLAG_NOT_IN_REMOTE',
              message: FALLBACK_USED_FLAG_NOT_IN_REMOTE
            };
            warnings.push(warning);
            return response;
          }
        } // if there's still no match throw and error


        throw new Error('Flag not found');
      }

      const {
        cohorts,
        defaultValue,
        name,
        type
      } = flagConfig;
      if (type && type.toLowerCase() !== 'boolean' && !isQueryAll) throw new Error('Flag not found'); // if there are no cohorts (ie fallbackConfig) return early

      if (!cohorts || cohorts.length < 1) {
        const _addStorageWarningsWarnings = this.addStorageWarnings();

        warnings = mergeWarnings(_addStorageWarningsWarnings);
        const _result = {
          clientId: context.clientId,
          flagId: flagConfig.id,
          flagName: name,
          enabled: flagConfig.defaultValue,
          updatedSinceLastQuery: true,
          warnings: warnings
        };
        _result.updatedSinceLastQuery = this.checkIfFlagUpdated(_result);
        this.cacheFlagResults(_result);
        return _result;
      }

      flagName = name; // determine the feature's cohort config

      const cohortConfig = this.getCohortConfig(context, cohorts);
      let {
        rolloutValue
      } = cohortConfig;
      const {
        stickinessProperty
      } = cohortConfig;
      rolloutValue = rolloutValue || '0'; // create the hash and 'user feature index' (derived from hash)

      const saltKey = flagConfig.id;
      hashId = this.context.userId ? this.context.userId : undefined; // use the ffUserId if specified by cohort config

      if (stickinessProperty === 'ffUserId') {
        operationalId = this.featureFlagUserId;
        userIdType = 'ffUserId';
        hashId = operationalId;
      }

      const hash = hashId ? this.createHash(hashId, saltKey) : undefined;
      userFeatureIndex = hash ? this.getUserFeatureIndex(hash) : '-1';
      enabled = parseInt(userFeatureIndex, 10) < parseInt(rolloutValue, 10); // determine whether or not flag is enabled for the given user
      // attempt to use the defaultValue if either there is no cohort config match or an invalid userFeatureIndex

      if (parseInt(rolloutValue, 10) < 0 || parseInt(userFeatureIndex, 10) < 0 || parseInt(userFeatureIndex, 10) > 99) {
        if (typeof defaultValue === 'undefined') {
          // try fallback config
          if (fallbackConfig) {
            const response = getFallbackConfig(fallbackConfig, flagId);
            if (response) return response; // if there's still no match throw and error

            throw new Error('Flag not found');
          }

          throw new Error('Unable to determine flag default value');
        }

        enabled = defaultValue;
      }
    } catch (error) {
      throw new Error(`Failed to query feature flag: ${error}`);
    }

    const addStorageWarningsWarnings = this.addStorageWarnings();
    warnings = mergeWarnings(addStorageWarningsWarnings);
    const result = {
      flagId: flagId,
      flagName: flagName,
      enabled: enabled,
      updatedSinceLastQuery: true,
      clientId: context.clientId,
      userId: operationalId,
      userIdType: userIdType,
      warnings: warnings
    };
    result.updatedSinceLastQuery = this.checkIfFlagUpdated(result);
    this.cacheFlagResults(result);
    return result;
  }
  /**
   * Checks availability of all features for a specific user
   * @return {IQueryFeatureResult[]} array of feature flag data objects
   */


  async queryAllFeatureFlags() {
    var _this = this;

    await this.init();
    const {
      config
    } = this;
    if (!config || !config.flags) throw new Error('No config file or invalid config file detected.');
    const featureFlagResponse = {
      anyFlagsUpdatedSinceLastQuery: false,
      globalWarnings: this.globalWarnings,
      results: []
    };
    const promises = config.flags.map(async function (flag) {
      const featureFlagData = await _this.queryFeatureFlag(flag.id, true);
      if (featureFlagData.updatedSinceLastQuery === true) featureFlagResponse.anyFlagsUpdatedSinceLastQuery = true;
      featureFlagResponse.results.push(featureFlagData);
    });
    return Promise.all(promises).then(() => featureFlagResponse);
  }

}

export { FeatureFlagClient };
